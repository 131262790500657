import { DataAccess } from "../dataaccess/data.access";

export enum DeviceType {
    IOS = "IOS",
    ANDROID = "ANDROID",
    UNKNOWN = "UNKNOWN",
}

export class DeviceService {
    public static async registerTokenWithStrType(deviceToken: string, deviceType: keyof typeof DeviceType) {
        return this.registerToken(deviceToken, DeviceType[deviceType as keyof typeof DeviceType]);
    }

    public static async registerToken(deviceToken: string, deviceType: DeviceType) {
        if (deviceType !== DeviceType.IOS && deviceType !== DeviceType.ANDROID) {
            console.error("Invalid device type: " + deviceType);
            return;
        }
        return DataAccess.post(
            "/device.json?deviceToken=" +
                encodeURIComponent(deviceToken) +
                "&deviceType=" +
                encodeURIComponent(deviceType),
        );
    }
}
