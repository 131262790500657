import { AuthService, JwtTokenHolder } from "./auth.service";
import { S25Util } from "../util/s25-util";
import { S25Const } from "../util/s25-const";
import { DataAccess } from "../dataaccess/data.access";

export interface SecureStorage {
    get(success: (value: string) => void, error: (error: any) => void, key: string): void;
    set(success: () => void, error: (error: any) => void, key: string, value: string): void;
    remove(success: () => void, error: (error: any) => void, key: string): void;
}

export class CordovaService {
    private static checkingJwt = false;
    private static jwtCheckInterval = S25Const.ms.min;
    private static expirationBuffer = S25Const.ms.hour;
    private static S25AppJwtTokenHolder = "S25AppJwtTokenHolder";
    private static S25AppJwtIssuedAt = "S25AppJwtIssuedAt";
    private static ss: SecureStorage = null;

    public static setSS(ss: SecureStorage) {
        CordovaService.ss = ss;
        if (!ss) {
            console.error("Falling back to localStorage");
            CordovaService.ss = {
                get: (success: (value: string) => void, error: (error: any) => void, key: string) => {
                    success(S25Util.localStorageGet(key));
                },
                set: (success: () => void, error: (error: any) => void, key: string, value: string) => {
                    S25Util.localStorageSet(key, value);
                    success();
                },
                remove: (success: () => void, error: (error: any) => void, key: string) => {
                    S25Util.localStorageRemove(key);
                    success();
                },
            };
        }
    }

    public static setSecureStorage(key: string, value: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            CordovaService.ss?.set(
                () => {
                    resolve(true);
                },
                (error: any) => {
                    reject(error);
                },
                key,
                value,
            );
        });
    }

    public static getSecureStorage(key: string): Promise<string> {
        return new Promise((resolve, reject) => {
            CordovaService.ss?.get(
                (value: string) => {
                    resolve(value);
                },
                (error: any) => {
                    resolve(null);
                },
                key,
            );
        });
    }

    public static removeSecureStorage(key: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            CordovaService.ss?.remove(
                () => {
                    resolve(true);
                },
                (error: any) => {
                    resolve(true);
                },
                key,
            );
        });
    }

    public static async initJwtTokenHolder(): Promise<void> {
        S25Const.authDefer.promise.then(async () => {
            let now = new Date().getTime();
            const [jwtTokenHolder, error] = await S25Util.Maybe<JwtTokenHolder>(AuthService.getJwtTokenHolder());
            if (error) {
                console.error(error);
                return;
            }
            await CordovaService.setSecureStorage(CordovaService.S25AppJwtTokenHolder, JSON.stringify(jwtTokenHolder));
            await CordovaService.setSecureStorage(CordovaService.S25AppJwtIssuedAt, now + "");
            DataAccess.setJwt(jwtTokenHolder.jwtToken);
            CordovaService.autoRefreshJwt(jwtTokenHolder);
        });
    }

    private static async refreshJwtTokenHolder(jwtTokenHolder: JwtTokenHolder): Promise<void> {
        let now = new Date().getTime();
        const [refreshedJwtTokenHolder, error] = await S25Util.Maybe<JwtTokenHolder>(
            AuthService.refreshJwt(jwtTokenHolder),
        );
        if (error || !refreshedJwtTokenHolder) {
            console.error(error || "No refreshed JWT");
            AuthService.invalidateAuth();
            return;
        }
        await CordovaService.setSecureStorage(
            CordovaService.S25AppJwtTokenHolder,
            JSON.stringify(refreshedJwtTokenHolder),
        );
        await CordovaService.setSecureStorage(CordovaService.S25AppJwtIssuedAt, now + "");
        DataAccess.setJwt(jwtTokenHolder.jwtToken);
        CordovaService.checkJwt(refreshedJwtTokenHolder);
    }

    public static autoRefreshJwt(jwtTokenHolder: JwtTokenHolder) {
        if (CordovaService.checkingJwt) {
            return;
        }
        CordovaService.checkingJwt = true;
        this.checkJwt(jwtTokenHolder);
    }

    private static checkJwt(jwtTokenHolder: JwtTokenHolder) {
        setTimeout(async () => {
            let jwtIssuedAtStr = await CordovaService.getSecureStorage("S25AppJwtIssuedAt");
            let jwtIssuedAt = parseInt(jwtIssuedAtStr);
            let now = new Date().getTime();
            let refreshTokenExpiration = jwtTokenHolder.refreshTokenExpirationInMinutes * 60 * 1000;
            if (await CordovaService.isJwtExpired(jwtIssuedAt, jwtTokenHolder)) {
                // Token expired or about to expire
                if (now - jwtIssuedAt > refreshTokenExpiration) {
                    console.error("Token completely expired");
                    AuthService.invalidateAuth();
                    return;
                }
                await CordovaService.refreshJwtTokenHolder(jwtTokenHolder);
                return;
            }
            this.checkJwt(jwtTokenHolder); // token still valid
        }, CordovaService.jwtCheckInterval);
    }

    private static async isJwtExpired(jwtIssuedAt: number, jwtTokenHolder: JwtTokenHolder): Promise<boolean> {
        let now = new Date().getTime();
        let tokenExpiration = jwtTokenHolder.tokenExpirationInMinutes * 60 * 1000;
        return now - jwtIssuedAt >= tokenExpiration - CordovaService.expirationBuffer;
    }
}
