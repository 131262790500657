export namespace CustomAttributes {
    export const enum Type {
        Boolean = "B",
        Image = "I",
        LargeText = "X",
        Organization = 2,
        Contact = 3,
        Location = 4,
        Resource = 6,
        FileReference = "R",
        String = "S",
        Date = "D",
        Datetime = "E",
        Time = "T",
        Integer = "N",
        Float = "F",
        Duration = "L",
    }

    export const type = {
        DateTime: "E",
        Date: "D",
        Time: "T",
        Boolean: "B",
        Organization: 2,
        Location: 4,
        Resource: 6,
        Contact: 3,
        Float: "F",
        Integer: "N",
        Text: "S",
        LongText: "X",
        URL: "R",
        Image: "I",
    } as const;

    export const system = {
        sectionType: { id: -56 },
        subTerm: { id: -59 },
        uniqueSectionId: { id: -68 },
    } as const;
}
